var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({class:_vm.classes,attrs:{"headers":_vm.headers,"items":_vm.items,"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-header":_vm.customHeader,"hide-default-footer":"","show-select":_vm.showSelect},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary"}},'v-simple-checkbox',props,false),on))]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}},(_vm.defaultShowRows > 0)?{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
var isSelected = ref.isSelected;
var select = ref.select;
return [(index < _vm.currentShowRows)?_c('tr',{style:(_vm.rowHeight ? ("height:" + _vm.rowHeight + " !important"): '')},[(_vm.showSelect)?_c('td',[_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})],1):_vm._e(),_vm._l((_vm.headers),function(header,hIndex){return _c('td',{key:hIndex},[(item[header.value])?[_vm._v(" "+_vm._s(item[header.value])+" ")]:_vm._e()],2)})],2):_vm._e()]}}:null,_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotProps){return [_vm._t(name,null,null,slotProps)]}}}),(0 < _vm.defaultShowRows && _vm.currentShowRows < _vm.items.length)?{key:"footer",fn:function(){return [_c('base-container',{attrs:{"horizontal-center":""}},[_c('base-button',{staticClass:"label-medium",attrs:{"label":_vm.showMoreLabel,"text":"","icon":"fas fa-chevron-down"},on:{"click":_vm.readMoreList}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }