import { render, staticRenderFns } from "./BaseCheckBox.vue?vue&type=template&id=0defb81d&scoped=true&"
import script from "./BaseCheckBox.vue?vue&type=script&lang=ts&"
export * from "./BaseCheckBox.vue?vue&type=script&lang=ts&"
import style0 from "./BaseCheckBox.vue?vue&type=style&index=0&id=0defb81d&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-93e13427ca/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0defb81d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../.yarn/__virtual__/vuetify-loader-virtual-1cdf2554cb/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCheckbox,VIcon})
