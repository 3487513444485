var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-base-empty',{style:(_vm.styleMarginTop),attrs:{"title":_vm.fixedDocumentTitle,"need-display-alert":"","is-show-side-navigation":_vm.isShowSideNavigation}},[(_vm.isShowSideNavigation)?_c('div',{staticClass:"d-flex"},[_c('div',{style:({
        height: '100vh',
        width: _vm.sideNavigationWidth,
        marginTop: '-8rem',
      })},[_c('div',{staticClass:"background-grey",style:({
          height: '100%',
          width: _vm.sideNavigationWidth,
          position: 'fixed',
          top: '0',
          overflow: 'auto',
          paddingTop: '5rem',
        })},[_c('side-navigation',{model:{value:(_vm.isShowSideNavigation),callback:function ($$v) {_vm.isShowSideNavigation=$$v},expression:"isShowSideNavigation"}})],1)]),(_vm.beta)?_c('app-base-content-beta',{attrs:{"title":_vm.title,"is-show-side-navigation":_vm.isShowSideNavigation},scopedSlots:_vm._u([{key:"page-filter",fn:function(){return [_vm._t("page-filter")]},proxy:true}],null,true)},[_vm._t("default")],2):_c('app-base-content',{attrs:{"title":_vm.title,"bread-crumbs":_vm.breadCrumbs,"is-show-side-navigation":_vm.isShowSideNavigation}},[_vm._t("default")],2)],1):(_vm.beta)?_c('app-base-content-beta',{attrs:{"title":_vm.title,"is-show-side-navigation":_vm.isShowSideNavigation},scopedSlots:_vm._u([{key:"page-filter",fn:function(){return [_vm._t("page-filter")]},proxy:true}],null,true)},[_vm._t("default")],2):_c('app-base-content',{attrs:{"title":_vm.title,"bread-crumbs":_vm.breadCrumbs,"is-show-side-navigation":_vm.isShowSideNavigation}},[_vm._t("default")],2),_c('global-header',{staticClass:"global-header"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }