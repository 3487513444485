var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"ma-0",style:(_vm.styles),attrs:{"id":"global-header"}},[_c('base-responsive',{attrs:{"sm-and-down":""}},[_c('v-app-bar',{staticStyle:{"box-shadow":"0px 2px 5px 0px rgba(0, 0, 0, 0.10)"},attrs:{"color":"white px-0"}},[_c('base-container',{staticClass:"d-flex justify-space-between py-0 px-1",staticStyle:{"width":"100vw"}},[(_vm.isShowHomeBtn)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-height":"3rem"}},[_c('base-container',{attrs:{"horizontal-center":"","vertical-center":""}},[_c('img',{staticStyle:{"width":"1.8rem","cursor":"pointer"},attrs:{"src":require("@/assets/images/home/home.svg"),"alt":"HOME"},on:{"click":function($event){return _vm.moveHome($event)}}})])],1):(_vm.isShowHamburgerMenuSp)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-height":"3rem"}},[_c('base-button',{attrs:{"icon":"fas fa-bars"},on:{"click":function($event){return _vm.clickHamburgerMenuSp($event)}}})],1):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('base-container',{attrs:{"horizontal-center":"","vertical-center":""}},[_c('img',{staticStyle:{"width":"10rem","cursor":"pointer"},attrs:{"src":require("@/assets/images/logo/logotype.svg"),"alt":"Fairgrit®"},on:{"click":function($event){return _vm.moveHome($event)}}})])],1),(_vm.isNominee || _vm.isTmpUser)?_c('div',[_c('base-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('user-image',_vm._g({staticClass:"pt-2",attrs:{"no":_vm.userNo,"round":"","width":"2rem"}},on))]}}],null,false,1188587239)},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":"","direction-column":""}},[_c('user-image',{staticClass:"mb-4",attrs:{"no":_vm.userNo,"round":"","width":"3rem"}}),_c('base-typography',{attrs:{"value":_vm.userId,"center":""}}),_c('base-typography',{attrs:{"value":_vm.userName,"last":"","center":""}}),_c('base-divider',{attrs:{"short":""}}),_c('base-button',{attrs:{"label":"ログアウト","to":"/logout","outlined":""}})],1)],1)],1):_c('user-image',{staticClass:"pt-2",attrs:{"no":_vm.userNo,"round":"","width":"2rem"},on:{"click":function($event){return _vm.clickUserImageSp($event)}}})],1)],1),(_vm.isShowHamburgerMenuSp)?_c('v-navigation-drawer',{staticClass:"d-flex navigation-smart-phone-menu-z-index",staticStyle:{"position":"absolute","top":"0","left":"0","height":"100vh"},attrs:{"fixed":""},model:{value:(_vm.showLeftDrawer),callback:function ($$v) {_vm.showLeftDrawer=$$v},expression:"showLeftDrawer"}},[_c('v-list',{attrs:{"dense":""}},[(_vm.isShowHome)?_c('global-header-pull-down-sp',{attrs:{"pull-down-menu":{
            title: 'HOME',
            hidden: false,
            groups: [],
          },"selected":_vm.selectedMenuTitle === 'HOME',"to":_vm.isAdmin ? '/admin' : '/home',"left":true}}):_vm._e(),_vm._l((_vm.leftPullDownMenuList),function(pullDownMenu,index){return [_c('global-header-pull-down-sp',{key:("leftPullDownMenu-" + index),attrs:{"pull-down-menu":pullDownMenu,"selected":_vm.selectedMenuTitle === pullDownMenu.title,"left":true}})]}),(_vm.rightPullDownMenuList.length > 0)?_c('v-divider'):_vm._e(),_vm._l((_vm.rightPullDownMenuList),function(pullDownMenu,index){return [_c('global-header-pull-down-sp',{key:("rightPullDownMenu-" + index),attrs:{"pull-down-menu":pullDownMenu,"selected":_vm.selectedMenuTitle === pullDownMenu.title,"left":true}})]})],2)],1):_vm._e(),_c('side-navigation',{staticStyle:{"position":"absolute","top":"0","right":"0","height":"100vh"},attrs:{"right":""},model:{value:(_vm.showRightDrawer),callback:function ($$v) {_vm.showRightDrawer=$$v},expression:"showRightDrawer"}})],1),_c('base-responsive',{attrs:{"md-and-up":""}},[_c('v-app-bar',{staticClass:"py-0 pr-2 pl-0",staticStyle:{"box-shadow":"0px 2px 5px 0px rgba(0, 0, 0, 0.10)"},attrs:{"height":"64px","color":"white"}},[_c('div',{staticClass:"d-flex flex-nowrap align-stretch w-100",staticStyle:{"min-height":"3rem"}},[_c('div',{staticClass:"mr-6",staticStyle:{"width":"2.5rem"}},[_c('base-container',{attrs:{"horizontal-center":"","vertical-center":""}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/logo/logotype_pc.svg"),"alt":"Fairgrit®"}})])],1),_c('div',[(_vm.ready)?_c('div',{staticClass:"d-flex align-center white"},[(_vm.isShowHome)?_c('div',{staticClass:"d-flex align-center h-100"},[(_vm.isShowHome)?_c('global-header-pull-down',{attrs:{"pull-down-menu":{
                  title: 'HOME',
                  hidden: false,
                  groups: [],
                },"selected":_vm.selectedMenuTitle === 'HOME',"to":_vm.isAdmin ? '/admin' : '/home'}}):_vm._e()],1):_vm._e(),_vm._l((_vm.leftPullDownMenuList),function(pullDownMenu,index){return [_c('global-header-pull-down',{key:("leftPullDownMenu-" + index),attrs:{"pull-down-menu":pullDownMenu,"selected":_vm.selectedMenuTitle === pullDownMenu.title}})]}),_c('div',{class:{
                'd-flex': true,
                'userImageOnly': _vm.isNominee,
              },staticStyle:{"position":"absolute","right":"0"}},[_vm._l((_vm.rightPullDownMenuList),function(sideMenu,index){return [_c('global-header-pull-down',{key:("rightPullDownMenu-" + index),attrs:{"pull-down-menu":sideMenu,"selected":_vm.selectedMenuTitle === sideMenu.title}})]}),(_vm.rightPullDownMenuList.length > 0)?_c('v-divider',{staticClass:"my-3",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"d-flex align-center py-0 px-6"},[(_vm.isNominee || _vm.isTmpUser)?_c('base-container',{attrs:{"horizontal-center":"","vertical-center":""}},[_c('base-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('user-image',_vm._g({staticClass:"mt-3 mb-2",staticStyle:{"cursor":"pointer"},attrs:{"no":_vm.userNo,"round":"","width":"2.5rem"}},on))]}}],null,false,738756004)},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":"","direction-column":""}},[_c('user-image',{staticClass:"mb-4",attrs:{"no":_vm.userNo,"round":"","width":"3rem"}}),_c('base-typography',{attrs:{"value":_vm.userId,"center":""}}),_c('base-typography',{attrs:{"value":_vm.userName,"last":"","center":""}}),_c('base-divider',{attrs:{"short":""}}),_c('base-button',{attrs:{"label":"ログアウト","to":"/logout","outlined":""}})],1)],1)],1):_c('base-container',[_c('div',{class:_vm.selectedClass},[_c('user-image',{staticClass:"mt-3 mb-2",staticStyle:{"cursor":"pointer"},attrs:{"no":_vm.userNo,"round":"","width":"2.5rem"},on:{"click":function($event){return _vm.clickUserImage(_vm.myMenuHeader.to, $event)}}})],1),(!_vm.isNominee && !_vm.isTmpUser)?_c('global-header-pull-down',{staticClass:"pl-2 pr-0",attrs:{"pull-down-menu":{
                      title: ("" + (_vm.myMenuHeader.title)),
                      hidden: false,
                      groups: [],
                    },"selected":_vm.selectedMenuTitle === _vm.myMenuHeader.title,"to":_vm.myMenuHeader.to}}):_vm._e()],1)],1)],2)],2):_c('div',{staticClass:"flex-grow-1 d-flex align-center px-2"},[_c('base-loader',{attrs:{"type":"heading","width":"100%"}})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }