var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.to !== '')?_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"2.5rem"}},[_c('a',{class:{
      'headerTitle': true,
      'body-regular': true,
      'selectedColor': _vm.selected,
      'borderLeft': _vm.selected && !_vm.isLeft,
      'borderRight': _vm.selected && _vm.isLeft,
    },attrs:{"href":_vm.to},on:{"click":_vm.click}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_c('div',{staticClass:"pl-0",class:{
    'headerTitle': true,
    'body-regular': true,
    'selected': _vm.selected,
  },on:{"click":_vm.click}},[_vm._v(" "+_vm._s(_vm.title)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }